import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import { GlobalControl } from '@/classes/stats/GlobalControl.js'
import StatsTitle from '@/components/Stats/StatsTitle/StatsTitle.vue'
import Contacts from '../Contacts/ContactsOfGroups.vue'
import DateRange from '@/components/DateRange/DateRange.vue'
import EmailValidationService from '@/services/email-validation.service'
import getEnv from '@/util/env'
import EventBus from '@/util/EventBus'

export default {
  name: 'DdbbValidations',
  components: { HeaderTopDashboard, StatsTitle, DateRange, Contacts },
  data: function () {
    return {
      globalControl: new GlobalControl(),
      loading: true,
      options: {},
      content: [],
      searchTerm: '',
      itemsPerPage: 10,
      pageCount: 0,
      page: 1,
      totalItems: 0,
      emailValidations: [],
      ready: true,
      debouncedInput: undefined,
      timeout: undefined,
      startDate: undefined,
      endDate: undefined,
      resultErrorDialog: false,
      refresh: undefined,
    }
  },
  methods: {

    checkResult (id) {
      EmailValidationService.checkResult(id)
      .then(
        () => {
          this.downloadFile(id)
        },
        () => {
          this.resultErrorDialog = true
          },
      )
    },

    downloadFile (id) {
      const user = JSON.parse(localStorage.getItem('user'))
      window.open(getEnv('VUE_APP_API_URL') + 'api/email-validation/result/' + id + '?token=' + user.token, '_blank')
    },
    selectDates (dates) {
      this.startDate = dates[0]
      this.endDate = dates[1]
      this.getDataFromApi()
    },

    getDataFromApi () {
      EventBus.$emit('showLoading', true)
      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
        startDate: this.startDate,
        endDate: this.endDate,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }

      this.content = []
      this.loading = true
      EmailValidationService.getEmailValidations(params)
        .then(
          (response) => {
            this.emailValidations = response.data
            this.itemsPerPage = parseInt(response.per_page)
            this.page = parseInt(response.current_page)
            this.totalItems = parseInt(response.total)
          },
          () => { },
        )
        .finally(() => {
          this.loading = false
          EventBus.$emit('showLoading', false)
        })
    },
  },
  computed: {
    headers () {
      return [
        { text: this.$t('Fecha'), align: 'start', value: 'created_at' },
        { text: this.$t('Grupos'), align: 'start', sortable: false, value: 'groups' },
        { text: this.$t('Total'), align: 'start', value: 'total' },
        { text: this.$t('Procesados'), align: 'start', value: 'processed' },
        { text: this.$t('Validos'), align: 'start', value: 'existing' },
        { text: this.$t('Coste'), align: 'start', value: 'cost' },
        { text: this.$t('Estado'), align: 'start', sortable: false, value: 'status' },
        { text: this.$t('Acciones'), value: 'actions', sortable: false },
      ]
    },
    params (nv) {
      return {
        ...this.options,
        query: this.searchTerm,
        startDate: this.startDate,
        endDate: this.endDate,
      }
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchTerm = val
        }, 700)
      },
    },
  },
  watch: {
    globalControl: {
      handler: function () {
        this.getDataFromApi()
      },
      deep: true,
    },
    params: {
      handler () {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  created () {
    this.refresh = setInterval(() => {
      const existNotFinished = this.emailValidations.filter((object) => {
        return !['finished', 'failed', 'cancelled'].includes(object.status)
      })

      if (existNotFinished.length > 0) {
        this.getDataFromApi()
      }
    }, 5000)
  },
  mounted () {
    this.startDate = this.globalControl.initDate
    this.endDate = this.globalControl.endDate
  },
  beforeDestroy () {
    clearInterval(this.refresh)
  },
}
