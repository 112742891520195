import { Service } from './service'

const PATH = 'email-validation/'

class EmailValidationService extends Service {
  getValidationCost () {
    return this.get(PATH + 'validation-cost')
  }

  getEmailValidations (params) {
    return this.get(PATH + 'email-validations', params)
  }

  checkResult (id) {
    return this.get(PATH + 'check-result/' + id)
  }
}

export default new EmailValidationService()
